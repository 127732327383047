import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { ValidationService } from 'src/app/services/validation.service';
import { Entrant } from 'src/interfaces/entrants/entrant';
import { Question } from 'src/interfaces/questions/question';
import { PageBlockFormQuestionComponent } from '../page-block-form-question.component';
import { ExecuteService } from 'src/app/services/execute.service';
import moment from 'moment';
import { UtilsPublicService } from 'src/app/services/utils-public.service';
import { WindowApp } from 'src/interfaces/windowApp';

declare var window: WindowApp;

@Component({
    selector: 'app-page-block-form-question-dob',
    templateUrl: './page-block-form-question-dob.component.html',
    styleUrls: ['./page-block-form-question-dob.component.scss']
})
export class PageBlockFormQuestionDOBComponent implements OnInit {

    @Input() formGroup!: FormGroup;
    @Input() question!: Question;
    @Input() entrant?: Entrant;
    @Input() controller?: PageBlockFormQuestionComponent;

    controlName: string = this.question?.key;
    control: FormControl = this.formBuilder?.control('', []);

    selectedDay?: number;
    selectedMonth?: number;
    selectedYear?: number;

    days: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
    months: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    years: number[] = Array.from({ length: (new Date().getFullYear()) - (new Date().getFullYear() - 100) + 1 }, (_, y) => (new Date().getFullYear() - 100) + y).reverse();

    calculatedAge: number = 0;

    constructor(private formBuilder: FormBuilder, public Validation: ValidationService, private UtilsPublic: UtilsPublicService, private Execute: ExecuteService) {
    }

    ngOnInit(): void {
        this.controlName = this.question.key;

        this.formGroup.addControl(this.controlName, this.control);

        this.setValidation();
        this.Validation.setCustomValidation(this.question, this.question.options.validations, this.control);
    }

    getMonth(index: number): string {
        return moment().set({ year: 2000, months: (index - 1), day: 10 }).format('MMMM');
    }

    setValidation(): void {
        if (!this.question.options.typeSpecificOptions.allowSubmit) {
            this.control.addValidators([this.validatorValid(), this.validatorAge()]);
        }

        setTimeout(() => {
            if (this.entrant && this.entrant?.answers) {
                const date = moment(this.entrant?.answers[this.question.key].split('-').reverse().join('-')).utc(true); // flip it from DD-MM-YYYY to YYYY-MM-DD
                this.selectedDay = date.date()
                this.selectedMonth = date.month() + 1;
                this.selectedYear = date.year();
            }
        }, 250);

        this.Execute.registerEntrantModifier(entrant => entrant.answers[this.question.key] = moment({ year: this.selectedYear, month: this.selectedMonth ? (this.selectedMonth - 1) : 0, day: this.selectedDay }).utc(true).format('DD-MM-YYYY'));
    }
    validatorAge(): ValidatorFn {
        return (): { [key: string]: any } | null => {
            return this.isValidAge() ? null : { 'tooYoung': true }
        };
    }
    validatorValid(): ValidatorFn {
        return (): { [key: string]: any } | null => {
            return this.isValidDate() ? null : { 'invalidDate': true }
        };
    }

    change(): void {
        this.control.markAsTouched();
        if (this.selectedDay && this.selectedMonth && this.selectedYear) {
            this.control.setValue(moment({ year: this.selectedYear, month: this.selectedMonth ? (this.selectedMonth - 1) : 0, day: this.selectedDay }).utc(true).format('YYYY-MM-DD'));
            this.control.updateValueAndValidity();
        }
    }

    isValidDate(): boolean {
        if (this.selectedDay && this.selectedMonth && this.selectedYear) {
            return this.UtilsPublic.dateIsValid({ year: this.selectedYear, month: this.selectedMonth, day: this.selectedDay });
        } else {
            return false;
        }
    }

    isValidAge(): boolean {

        if (this.question.options.typeSpecificOptions.allowSubmit) {
            return true;
        } else {
            if ((window.minimumAge || this.question.options.typeSpecificOptions.minimumAge)) {
                if (this.selectedYear && this.selectedMonth && this.selectedDay && (window.minimumAge || this.question.options.typeSpecificOptions.minimumAge)) {
                    return this.UtilsPublic.dateIsOldEnough((window.minimumAge || this.question.options.typeSpecificOptions.minimumAge), { year: this.selectedYear, month: this.selectedMonth, day: this.selectedDay });
                }
                return false;
            } else {
                return true;
            }

        }
    }

    ngOnDestroy(): void {
        this.formGroup.removeControl(this.controlName);
    }

}