import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ValidationService } from 'src/app/services/validation.service';
import { Entrant } from 'src/interfaces/entrants/entrant';
import { Question } from 'src/interfaces/questions/question';

@Component({
    selector: 'app-page-block-form-question-textarea',
    templateUrl: './page-block-form-question-textarea.component.html',
    styleUrls: ['./page-block-form-question-textarea.component.scss']
})
export class PageBlockFormQuestionTextareaComponent implements OnInit {

    @Input() formGroup!: FormGroup;
    @Input() question!: Question;
    @Input() entrant?: Entrant;

    controlName: string = this.question?.key;
    control: FormControl = this.formBuilder?.control('', []);

    constructor(private formBuilder: FormBuilder, public Validation: ValidationService) {
    }

    ngOnInit(): void {
        this.controlName = this.question.key;

        this.setValidation();
        this.Validation.setCustomValidation(this.question, this.question.options.validations, this.control);
    }

    setValidation(): void {
        this.formGroup.addControl(this.controlName, this.control);

        setTimeout(() => {
            if (this.entrant && this.entrant?.answers) {
                this.control.setValue(this.entrant?.answers[this.question.key]);
            }
        }, 250);
    }

    ngOnDestroy(): void {
        this.formGroup.removeControl(this.controlName);
    }
}
