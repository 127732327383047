import { Component, Input, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilsPublicService } from 'src/app/services/utils-public.service';
import { Question } from 'src/interfaces/questions/question';

@Component({
    selector: 'app-page-block-form-question-content',
    templateUrl: './page-block-form-question-content.component.html',
    styleUrls: ['./page-block-form-question-content.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageBlockFormQuestionContentComponent implements OnInit, OnDestroy {

    @Input() question!: Question;
    scopeUpdater: any;

    constructor(private sanitizer: DomSanitizer, private changeDetectorRef: ChangeDetectorRef, private UtilsPublic: UtilsPublicService) {
    }

    ngOnInit(): void {
        if (this.UtilsPublic.isBrowser() && window.location.pathname.indexOf('/admin') === 0) {
            this.scopeUpdater = setInterval(() => {
                this.changeDetectorRef.detectChanges();
            }, 1500);
        }
    }

    transform(html: string = '') {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }

    ngOnDestroy(): void {
        if (this.scopeUpdater) {
            clearInterval(this.scopeUpdater);
        }
    }
}
