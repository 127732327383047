import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ValidationService } from 'src/app/services/validation.service';
import { Entrant } from 'src/interfaces/entrants/entrant';
import { PageBlockFormQuestion } from 'src/interfaces/pages/pageBlockFormQuestion';
import { Question } from 'src/interfaces/questions/question';
import { QuestionValue } from 'src/interfaces/questions/questionValues';

@Component({
    selector: 'app-page-block-form-question-button',
    templateUrl: './page-block-form-question-button.component.html',
    styleUrls: ['./page-block-form-question-button.component.scss']
})
export class PageBlockFormQuestionButtonComponent implements OnInit, PageBlockFormQuestion {

    @Input() formGroup!: FormGroup;
    @Input() question!: Question;
    @Input() entrant?: Entrant;

    controlName: string = this.question?.key;
    control: FormControl = this.formBuilder?.control('', []);

    constructor(private formBuilder: FormBuilder, public Validation: ValidationService) {
    }

    ngOnInit(): void {
        this.controlName = this.question.key;

        this.setValidation();
        this.Validation.setCustomValidation(this.question, this.question.options.validations, this.control);
    }

    setValidation(): void {
        this.control.addValidators(Validators.pattern(new RegExp((this.question.options.values?.map((option) => option.value).join('|')) || '')));

        this.formGroup.addControl(this.controlName, this.control);


        setTimeout(() => {
            if (this.entrant && this.entrant?.answers) {
                this.control.setValue(this.entrant?.answers[this.question.key]);
            }
        }, 250);
    }

    setValue(option: QuestionValue): void {
        if (!option.disabled) {
            this.control.setValue(option.value);
        }
    }


    ngOnDestroy(): void {
        this.formGroup.removeControl(this.controlName);
    }
}
