import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';
import { hiddenIfService } from 'src/app/services/hiddenIf.service';
import { UtilsPublicService } from 'src/app/services/utils-public.service';
import { Entrant } from 'src/interfaces/entrants/entrant';
import { Form } from 'src/interfaces/form';
import { PageBlock } from 'src/interfaces/pages/pageBlock';
import { Question } from 'src/interfaces/questions/question';
import { QuestionValue } from 'src/interfaces/questions/questionValues';
import { WindowApp } from 'src/interfaces/windowApp';

declare var window: Window & WindowApp;

@Component({
    selector: 'app-page-block-form-question',
    templateUrl: './page-block-form-question.component.html',
    styleUrls: ['./page-block-form-question.component.scss']
})
export class PageBlockFormQuestionComponent implements OnInit {
    @Input() block!: PageBlock;
    @Input() formGroup!: FormGroup;
    @Input() stepperFormGroups?: FormGroup[];
    @Input() question!: Question;
    @Input() entrant?: Entrant;
    loading: boolean = true;
    controller: PageBlockFormQuestionComponent;
    adminMode: boolean = false;
    form!: Form;

    constructor(private route: ActivatedRoute, private router: Router, private UtilsPublic: UtilsPublicService, private HiddenIf: hiddenIfService, private Config: ConfigService) {
        this.controller = this;
    }

    async ngOnInit(): Promise<void> {
        if (this.block && this.block.component && 'questions' in this.block.component)
            this.form = this.block.component;

        if (!this.question.options.branding)
            this.question.options.branding = { appearance: 'outline', hideRequired: false, floatLabel: 'auto', placeholder: this.question.label, hint: '' };

        this.adminMode = this.router.url.toString().indexOf('/admin/') !== -1;

        if (this.UtilsPublic.isBrowser()) {
            if (this.question.options.valuesFromConfig && !this.adminMode) {
                this.question.options.values = [];

                let config = await this.Config.getConfig(this.question.options.valuesFromConfig, true);
                if (config && config.data && Array.isArray(config.data)) {
                    for (const option of config.data) {
                        this.question.options.values?.push(option);
                    }
                } else {
                    config = await this.Config.getConfig(this.question.options.valuesFromConfig, false);
                    if (config && config.data && Array.isArray(config.data)) {
                        for (const option of config.data) {
                            this.question.options.values?.push(option);
                        }
                    }
                }

                this.loading = false;

            } else {
                this.loading = false;
            }
            if (this.question.options.allowOverride)
                window.sdk.questions[this.question.key] = this.question;
        }
    }

    isQuestionVisible(): Boolean {
        if (this.adminMode === true && this.route.snapshot.queryParamMap.get('enableBranching') !== 'true') {
            return true;
        }
        if (!this.form || !this.form.questions) {
            return true;
        }
        // true can just return out

        const isAcceptable = this.HiddenIf.isAcceptable(this.question, this.form, this.stepperFormGroups || this.formGroup).result;
        if (!isAcceptable) {
            // into reset value to deal with hiding questions' default values
            this.resetValue();
        }
        return isAcceptable;

    }

    isAnswerVisible(question: Question, optionValue: QuestionValue): boolean {
        // answer visibility is not the same sort of logic as regular hiddenIF
        if (this.route.snapshot.queryParamMap.get('enableBranching') === 'false') {
            return true;
        }
        if (optionValue && optionValue['hiddenIf'] === undefined) {
            return true;
        } else if (optionValue['hiddenIf'] === false) {
            return true;
        } else if (optionValue['hiddenIf'] === true) {
            this.checkIfValueIsValid(question, optionValue);
            return false;
        } else if (optionValue['hiddenIf'] && optionValue['hiddenIf'].length) {
            for (const hiddenObj of optionValue['hiddenIf']) {
                if (hiddenObj.key === '[nowDate]') { // special hiddenIf for rendering based on current dateTime
                    const gtOrLt = Object.keys(hiddenObj)[1];
                    const dateValue = new Date(hiddenObj[gtOrLt]);

                    if ((gtOrLt === 'lt' && dateValue > new Date()) || (gtOrLt === 'gt' && dateValue < new Date())) { // hidden if current date is less than selected date
                        this.checkIfValueIsValid(question, optionValue);
                        return false;
                    }
                    return true;
                }

                if (hiddenObj.key.indexOf('jQuery:') !== -1) {
                    const selector = hiddenObj.key.replace('jQuery:', '');
                    if (hiddenObj.visible === 'visible') {
                        if (window['$'](selector).is(':visible') === true)
                            return false;
                    } else if (hiddenObj.visible === 'notVisible') {
                        if (window['$'](selector).is(':visible') === false)
                            return false;
                    }
                }


                if (hiddenObj['value'] && this.getAnswer(hiddenObj['key']) === hiddenObj['value']) {
                    this.checkIfValueIsValid(question, optionValue);
                    return false;
                }
                if (hiddenObj['valueNot'] && this.getAnswer(hiddenObj['key']) !== hiddenObj['valueNot']) {
                    this.checkIfValueIsValid(question, optionValue);
                    return false;
                }
                if (hiddenObj['valueIn'] && this.getAnswer(hiddenObj['key']).length > 0 && this.getAnswer(hiddenObj['key']).includes(hiddenObj['valueIn'])) {
                    this.checkIfValueIsValid(question, optionValue);
                    return false;
                }
                if (hiddenObj['valueNotIn'] && this.getAnswer(hiddenObj['key']).length > 0 && !this.getAnswer(hiddenObj['key']).includes(hiddenObj['valueNotIn'])) {
                    this.checkIfValueIsValid(question, optionValue);
                    return false;
                }
            }
            return true;
        } else {
            this.checkIfValueIsValid(question, optionValue);
            return false;
        }


    }

    checkIfValueIsValid(question: Question, optionValue: QuestionValue): void {
        const key = question.key;
        const answer = this.getAnswer(key);
        const defaultAnswer = this.getDefaultAnswer();

        if (answer === optionValue.value) {
            if (defaultAnswer !== answer) {
                this.setAnswer(key, defaultAnswer);
            }
        }
    }

    getDefaultAnswer(): string {
        // default values logic needed
        return '';
    }

    resetValue(isQuestionVisible: boolean = false): boolean {
        this.formGroup.get(this.question.key)?.setValue('');
        return isQuestionVisible;
    }

    getAnswer(key: string): string {
        return this.formGroup.get(key)?.value;
    }

    setAnswer(key: string, value: any): void {
        this.formGroup.get(key)?.setValue(value);
    }
}
