<section [formGroup]="formGroup">
    <mat-form-field class="" [appearance]="Validation.getAppearance(question)" [hideRequiredMarker]="Validation.getHideRequired(question)" [floatLabel]="Validation.getFloatLabel(question)">
        <mat-label>{{question.label}}</mat-label>
        <img *ngIf="selectedValue && selectedValue.image" matPrefix [src]="selectedValue.image" loading="lazy">

        <input matInput [matAutocomplete]="auto" [formControl]="control" [placeholder]="Validation.getPlaceholder(question)">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="scopedDisplayFn()">
            <mat-option *ngFor="let value of filteredAnswers" [value]="value.value" [disabled]="value.disabled || !controller?.isAnswerVisible(question, value)">
                <img class="autocomplete-image {{value.subLabel ? 'has-sublabel': ''}}" *ngIf="value.image" alt="" [src]="value.image" loading="lazy">
                <mat-icon class="autocomplete-icon" *ngIf="value.icon">{{value.icon}}</mat-icon>
                <span class="autocomplete-label" *ngIf="value.label">{{value.label}}</span>
                <small class="autocomplete-sublabel" *ngIf="value.subLabel">{{value.subLabel}}</small>
            </mat-option>
        </mat-autocomplete>

        <mat-hint *ngIf="question.options.branding?.hint">{{question.options.branding?.hint}}</mat-hint>
        
        <button color="warn" mat-button *ngIf="selectedValue" (click)="reset();" matSuffix mat-icon-button aria-label="Clear">
            <mat-icon>close</mat-icon>
        </button>
        <mat-error *ngIf="control && control.hasError('required')">
            <span [innerHTML]="Validation.getRequiredMessage(question)">&nbsp;</span>
        </mat-error>
        <mat-error *ngIf="control && control.hasError('pattern')" [innerHTML]="Validation.getCustomErrorMessage(question, 'autocompleteMustSelect', 'You must select an option')">
            &nbsp;
        </mat-error>
    </mat-form-field>
</section>