import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { ExecuteService } from 'src/app/services/execute.service';
import { ValidationService } from 'src/app/services/validation.service';
import { Entrant } from 'src/interfaces/entrants/entrant';
import { EntrantFile } from 'src/interfaces/entrants/entrantFile';
import { Question } from 'src/interfaces/questions/question';

@Component({
    selector: 'app-page-block-form-question-file',
    templateUrl: './page-block-form-question-file.component.html',
    styleUrls: ['./page-block-form-question-file.component.scss']
})
export class PageBlockFormQuestionFileComponent implements OnInit, OnDestroy {
    @Input() formGroup!: FormGroup;
    @Input() question!: Question;
    @Input() entrant?: Entrant;

    controlName: string = this.question?.key;
    control: FormControl = this.formBuilder?.control('', []);
    allowedExtensionsAsString: string = '';
    customError: string = '';
    registeredModifiers: number = 0;
    selectedFiles: EntrantFile[] = [];

    constructor(private formBuilder: FormBuilder, public Validation: ValidationService, private Execute: ExecuteService) {
    }

    ngOnInit(): void {
        this.controlName = this.question.key;

        this.setValidation();
        this.Validation.setCustomValidation(this.question, this.question.options.validations, this.control);

        this.Execute.registerEntrantModifier((entrant: Entrant) => {
            if (!entrant.files)
                entrant.files = {};
            if (!(this.question.key in entrant.files))
                entrant.files[this.question.key] = [];
        });

        setTimeout(() => {
            if (this.entrant && this.entrant.files && this.entrant.files[this.question.key]) {
                this.selectedFiles = this.entrant.files[this.question.key];
            }
        }, 500);
    }

    setValidation(): void {
        this.formGroup.addControl(this.controlName, this.control);

        this.control.addValidators(Validators.requiredTrue);

        setTimeout(() => {
            if (typeof window === 'object' && this.question.options.typeSpecificOptions.multiple)
                (window.document.getElementById('file-' + this.question.key) as HTMLInputElement)!.multiple = true;
        }, 500);

        this.allowedExtensionsAsString = this.question.options.typeSpecificOptions.allowedExtensions.map((ext: string) => `.${ext}`).join(', ') as string;
    }

    isValidUpload(files: FileList): void {
        this.customError = '';
        this.control.setValue(false);

        for (const file of Array.from(files)) {
            const ext = file.name.split('.').pop() || '';

            if (this.question.options.typeSpecificOptions.minFileSize && parseInt((file.size / 1024).toFixed(0)) < (this.question.options.typeSpecificOptions.minFileSize as number))
                return void (this.customError = this.question.options.typeSpecificOptions.minFileSizeMessage || `File (${file.name}) is too small. Minimum size is (${this.question.options.typeSpecificOptions.minFileSize}KB)`)

            if (this.question.options.typeSpecificOptions.maxFileSize && parseInt((file.size / 1024).toFixed(0)) > (this.question.options.typeSpecificOptions.maxFileSize as number))
                return void (this.customError = this.question.options.typeSpecificOptions.maxFileSizeMessage || `File (${file.name}) is too large. Maximum size is (${this.question.options.typeSpecificOptions.maxFileSize}KB)`);

            if (this.question.options.typeSpecificOptions.allowedExtensions.length && (!ext || !this.question.options.typeSpecificOptions.allowedExtensions.includes(ext)))
                return void (this.customError = this.question.options.typeSpecificOptions.fileExtensionMessage || `File (${file.name}) has an unsupported file extension, allowed extensions are: (${this.question.options.typeSpecificOptions.allowedExtensions.join(', ')})`);




            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = (event) => {
                const entrantFile = {
                    filename: file.name,
                    size: file.size,
                    ext: ext,
                    upload: fileReader.result?.toString() || '',
                    uploaded: '',
                    mime: file.type,
                };

                if (this.question.options.typeSpecificOptions.multiple) {
                    if ((this.question.options.typeSpecificOptions.maxAmountOfFiles || 4) >= (this.selectedFiles.length + 1) && !(this.selectedFiles.find(saved => saved.filename === entrantFile.filename))) {
                        this.selectedFiles.push(entrantFile);
                    }
                } else if (this.selectedFiles.length === 0) {
                    this.selectedFiles.push(entrantFile);
                }

                this.checkAmountRequirements();
            };

            setTimeout(() => {
                if (window.document.getElementById('file-' + this.question.key)) {
                    (window.document.getElementById('file-' + this.question.key) as HTMLInputElement).value = '';
                }
            }, 1000);
        }

        this.control.setValue(true);

    }

    checkAmountRequirements(): void {
        if (this.question.options.typeSpecificOptions.multiple && this.question.options.typeSpecificOptions.minAmountOfFiles && this.selectedFiles.length < this.question.options.typeSpecificOptions.minAmountOfFiles) {
            this.customError = this.question.options.typeSpecificOptions.minFilesMessage || `You need to upload ${this.question.options.typeSpecificOptions.minAmountOfFiles} files`;
            this.control.setValue(false);
            this.control.updateValueAndValidity();
        }
    }

    updateModifier(): void {
        this.Execute.deregisterEntrantModifier(this.registeredModifiers);

        this.registeredModifiers = this.Execute.registerEntrantModifier((entrant: Entrant) => {
            if (!entrant.files)
                entrant.files = {};

            entrant.files![this.question.key] = this.selectedFiles;
        });
    }

    fileChange(): void {
        if (typeof window === 'object') {
            const file = window.document.getElementById('file-' + this.question.key) as HTMLInputElement;
            if (file.files)
                this.isValidUpload(file.files);

            this.control.markAsTouched();
        }
        setTimeout(() => {
            this.updateModifier();
        }, 1000);
    }


    ngOnDestroy(): void {
        this.formGroup.removeControl(this.controlName);
    }

    start(): void {
        if (typeof window === 'object') {
            window.document.getElementById('file-' + this.question.key)?.click();
        }
    }


}
