<p *ngIf="question.label && question.key === 'bot'">{{question.label}}</p>
<section class="images" *ngIf="question.options && question.options.typeSpecificOptions && question.options.typeSpecificOptions?.isImages">
    <div class="{{control.value === option.value ? 'selected':''}} {{option.disabled ? 'disabled' : ''}}" *ngFor="let option of question.options.values" (click)="setValue(option)">
        <img loading="lazy" src="{{option.image}}" />
        <p>{{option.label}}</p>
    </div>
</section>
<section class="buttons" *ngIf="!question.options.typeSpecificOptions?.isImages">
    <app-button-happy (click)="setValue(option)" color="accent" *ngFor="let option of question.options.values" [icon]="option.icon ? option.icon : undefined" [firstLine]="option.label" [secondLine]="option.subLabel || ''"
        [selected]="control.value === option.value" [disabled]="option.disabled"></app-button-happy>
</section>

<div [formGroup]="formGroup">
    <mat-form-field appearance="outline" class="always-hidden">
        <input type="text" matInput [formControlName]="controlName">
    </mat-form-field>
</div>