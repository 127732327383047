import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PageBlockFormQuestionComponent } from '../page-block-form-question.component';
import { ValidationService } from 'src/app/services/validation.service';
import { Entrant } from 'src/interfaces/entrants/entrant';
import { Question } from 'src/interfaces/questions/question';
import { ExecuteService } from 'src/app/services/execute.service';

@Component({
    selector: 'app-page-block-form-question-mask',
    templateUrl: './page-block-form-question-mask.component.html',
    styleUrls: ['./page-block-form-question-mask.component.scss']
})
export class PageBlockFormQuestionMaskComponent implements OnInit, OnDestroy {

    @Input() formGroup!: FormGroup;
    @Input() question!: Question;
    @Input() entrant?: Entrant;
    @Input() controller?: PageBlockFormQuestionComponent;
    
    controlName: string = this.question?.key || '';
    control: FormControl;
    validityInterval: any;
    maxLength: number = 1;
    totalFields: number = 4;

    constructor(
        private formBuilder: FormBuilder,
        public Validation: ValidationService,
        private Execute: ExecuteService
    ) {
        this.control = this.formBuilder.control('', []);
    }

    ngOnInit(): void {
        if (this.formGroup) {
            this.controlName = this.question.key;
            this.maxLength = this.question.options.typeSpecificOptions.maxLengthPerField || 1;
            this.totalFields = this.question.options.typeSpecificOptions.totalFields || 4;

            this.setValidation();
            this.Validation.setCustomValidation(this.question, this.question.options.validations, this.control);

            this.inputsArray.valueChanges.subscribe(() => {
                this.updateCombinedValue();
            });
        }
    }

    get inputsArray(): FormArray {
        return this.formGroup.get('maskInputs') as FormArray;
    }

    setValidation(): void {
        const formArray = this.formBuilder.array(this.createInputsArray());
        this.formGroup.addControl(this.controlName, this.control);
        this.formGroup.addControl('maskInputs', formArray);

        setTimeout(() => {
            if (this.entrant?.answers) {
                this.control.setValue(this.entrant.answers[this.question.key] || '');
            }
        }, 250);

        this.Execute.registerEntrantModifier(entrant => delete entrant.answers['maskInputs']);
    }

    createInputsArray(): FormControl[] {
        return new Array(this.totalFields).fill('').map(() => this.formBuilder.control('', []));
    }

    getFieldWidth(): string {
        return `${90 / this.totalFields}%`;
    }

    onInputChange(index: number, event: any) {
        const inputElement = event.target;
        const value = inputElement.value;

        if (value.length === this.maxLength && index < this.totalFields - 1) {
            const nextInput = document.getElementById(`input-${index + 1}`) as HTMLInputElement;
            nextInput?.focus();
        }
    }

    onBackspace(index: number, event: any) {
        const inputElement = event.target;

        if (inputElement.value === '' && index > 0) {
            const previousInput = document.getElementById(`input-${index - 1}`) as HTMLInputElement;
            previousInput?.focus();
        }
    }

    onPaste(event: ClipboardEvent) {
        const pastedData = event.clipboardData?.getData('text') || '';

        if (pastedData.length > 0) {
            const formArray = this.inputsArray;
            let currentIndex = 0;

            // Distribute the pasted data across the inputs
            formArray.controls.forEach((control, index) => {
                const slice = pastedData.slice(currentIndex, currentIndex + this.maxLength);
                control.setValue(slice);
                currentIndex += this.maxLength;

                if (slice.length === this.maxLength && index < this.totalFields - 1) {
                    const nextInput = document.getElementById(`input-${index + 1}`) as HTMLInputElement;
                    nextInput?.focus();
                }
            });
        }
    }

    updateCombinedValue(): void {
        const combinedValue = this.inputsArray.controls.map(control => control.value).join('');
        this.control.setValue(combinedValue);
        this.control.markAsDirty();
        this.control.markAsTouched();
        this.control.updateValueAndValidity();
    }

    ngOnDestroy(): void {
        clearInterval(this.validityInterval);
        if (this.formGroup && this.controlName) {
            this.formGroup.removeControl(this.controlName);
            this.formGroup.removeControl('maskInputs');
        }
    }
}
