import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { UtilsPublicService } from 'src/app/services/utils-public.service';
import { ValidationService } from 'src/app/services/validation.service';
import { Entrant } from 'src/interfaces/entrants/entrant';
import { PageBlockFormQuestion } from 'src/interfaces/pages/pageBlockFormQuestion';
import { Question } from 'src/interfaces/questions/question';

@Component({
    selector: 'app-page-block-form-question-slider',
    templateUrl: './page-block-form-question-slider.component.html',
    styleUrls: ['./page-block-form-question-slider.component.scss']
})
export class PageBlockFormQuestionSliderComponent implements OnInit, PageBlockFormQuestion {

    @Input() formGroup!: FormGroup;
    @Input() question!: Question;
    @Input() entrant?: Entrant;

    controlName: string = this.question?.key;
    control: FormControl = this.formBuilder?.control('', []);

    options: Question['options']['typeSpecificOptions']

    constructor(private formBuilder: FormBuilder, public Validation: ValidationService, public UtilsPublic: UtilsPublicService) {
    }

    ngOnInit(): void {
        this.controlName = this.question.key;
        this.options = this.question.options.typeSpecificOptions;
        this.setValidation();
        this.Validation.setCustomValidation(this.question, this.question.options.validations, this.control);
        this.setBranding();
    }

    showVertical(): boolean {
        return (this.options.vertical || (this.options.verticalPhones && this.UtilsPublic.isMobile()));
    }

    showInverted(): boolean {
        return (this.options.invert || this.UtilsPublic.isMobile() && this.options.invertPhones)
    }

    setValidation(): void {
        this.formGroup.addControl(this.controlName, this.control);

        setTimeout(() => {
            if (this.entrant && this.entrant?.answers) {
                this.control.setValue(this.entrant?.answers[this.question.key]);
            }
        }, 250);
    }

    display(): (value: number) => string {
        return (value: number) => {
            if (this.options && this.options.displayLabel)
                return this.options.displayLabel.replace('{{x}}', value.toString());

            return value.toString();
        }
    }

    setBranding(): void {
        if (this.question.options.typeSpecificOptions && this.question.options.typeSpecificOptions.colour) {

            const css = `
                .key-${this.question.key} .mat-slider.mat-accent .mat-slider-track-fill, 
                .key-${this.question.key} .mat-slider.mat-accent .mat-slider-thumb, 
                .key-${this.question.key} .mat-slider.mat-accent .mat-slider-thumb-label {
                    background-color: ${this.question.options.typeSpecificOptions.colour};
                }
            
            `;
            this.UtilsPublic.loadCSSInline(css, this.question.key + '-slider-colour');
        }
    }

    ngOnDestroy(): void {
        this.formGroup.removeControl(this.controlName);
        this.UtilsPublic.loadCSSInline('', this.question.key + '-slider-colour');
    }
}
