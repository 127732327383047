import { Injectable } from '@angular/core';
import { Page } from 'src/interfaces/pages/page';
import { SiteSettings } from 'src/interfaces/siteSettings';
import { UtilsPublicService } from './utils-public.service';
import { Form } from 'src/interfaces/form';

@Injectable({
    providedIn: 'root'
})
export class BrandingService {
    public fonts = [
        { label: 'Tahoma', value: `'Tahoma', sans-serif`, custom: false },
        { label: 'Arial', value: `'Arial', sans-serif`, custom: false },
        { label: 'Roboto', value: `'Roboto', sans-serif`, custom: false },
        { label: 'Helvetica Neue', value: `'Helvetica Neue', sans-serif`, custom: false },
        { label: 'Comic Sans', value: `'Comic Sans', sans-serif`, custom: false },
    ];

    constructor(private UtilsPublic: UtilsPublicService) {

    }

    getAllFonts(settings?: SiteSettings): any[] {
        if (settings && settings.resources && 'fonts' in settings.resources && settings.resources.fonts)
            return this.fonts.concat(settings.resources.fonts.map(font => ({ label: font.family, value: font.family, custom: true })));

        return this.fonts;
    }

    setBranding(settings: SiteSettings | Page): void {
        if (settings && settings.resources) {
            if (settings.resources && 'fonts' in settings.resources && settings.resources.fonts) {
                this.setCustomFonts(settings.resources.fonts);
            }
            if (settings.resources.css.length) {
                this.setCss(settings.resources.css);
            }
            if (settings.resources.js.length) {
                this.setJs(settings.resources.js);
            }
            if ('injectable' in settings && settings.injectable) {
                if (settings.injectable.css) {
                    this.UtilsPublic.loadCSSInline(settings.injectable.css);
                }
                if (settings.injectable.js) {
                    this.UtilsPublic.loadJSInline(settings.injectable.js);
                }
            }
        }
        if (settings && settings.metadata && 'google' in settings && settings.google) {
            if (settings.google.ga)
                this.setGoogleAnalytics(settings.google.ga);

            if (settings.google.gtm)
                this.setGoogleTagManager(settings.google.gtm);
        }

        let favicon = '/assets/favicon.png';

        if (settings && settings.metadata && 'favicon' in settings.metadata) {
            favicon = settings.metadata.favicon || '/assets/favicon.png';
        }

        this.setFavIcon(favicon);

        if (typeof settings === 'object' && 'database' in settings) { // site
            this.generate(settings, 'siteCSS');
        } else { // page
            this.generate(settings, 'pageCSS');
        }
    }
    setFormBranding(form: Form): void {
        let css = ``;
        if (form.branding?.backgroundColour && form.branding?.textColour)
            css += `
                .form-card {
                    background-color: ${form.branding?.backgroundColour};
                    color: ${form.branding?.textColour};
                }
                .mat-select-panel {
                    background: ${form.branding?.backgroundColour};
                }
                .cdk-overlay-container .mat-option {
                    background-color: ${form.branding?.backgroundColour};
                    color: ${form.branding?.textColour};
                }
                .cdk-overlay-container .mat-option:hover {
                    filter: invert(0.15);
                    background: inherit;
                }
                .form-card .mat-radio-outer-circle { border-color: ${form.branding?.textColour}; }
                `;

        if (form.branding.submitButtonBackgroundColour)
            css += `.form-container .form-submit { background-color: ${form.branding.submitButtonBackgroundColour}; }`;

        if (form.branding.submitButtonTextColour)
            css += `.form-container .form-submit { color: ${form.branding.submitButtonTextColour}; }`;

        this.UtilsPublic.loadCSSInline(css, 'formCss');
    }

    setCss(stylesheets: string[]): void {
        for (const stylesheet of stylesheets) {
            this.UtilsPublic.loadCSS(stylesheet);
        }

    }
    setJs(javascripts: string[]): void {
        for (const javascript of javascripts) {
            this.UtilsPublic.loadJS(javascript);
        }
    }

    setFavIcon(favicon: string): void {
        if (this.UtilsPublic.isBrowser() === false && (!!this.UtilsPublic.document.querySelector('link[rel=icon]')) === false) { // run once
            const head = this.UtilsPublic.document.querySelector('head');
            const headHTML = head?.innerHTML;
            const favIcon = `<link href="${favicon}" rel="shortcut icon" type="image/x-icon"/>
            <link rel="icon" type="image/x-icon" href="${favicon}">`;
            head!.innerHTML = headHTML + favIcon;
        }
    }

    generate(settings: SiteSettings | Page, id: string): void {
        let css = '';
        try {
            if (settings && typeof settings === 'object' && 'branding' in settings && settings.branding) {
                if (settings.branding.backgroundColour)
                    css += `html,body {background-color: ${settings.branding.backgroundColour} }`;

                if (settings.branding.textColour)
                    css += `html,body {color: ${settings.branding.textColour} }`;

                if (settings.branding.textFont)
                    css += `app-navigator,app-page-block-edit .mat-card {font-family: ${settings.branding.textFont}} .mat-form-field {font-family: inherit;}`;
            }
            try {
                this.UtilsPublic.document.getElementById(id)?.remove();
            } catch (err) {

            }
            this.UtilsPublic.loadCSSInline(css, id);
        } catch (err) { }
    }


    removeBranding(): void {
        if (this.UtilsPublic.isBrowser() && this.UtilsPublic.document) {

            this.UtilsPublic.document.querySelector('#formCSS')?.remove();
            this.UtilsPublic.document.querySelector('#siteCSS')?.remove();
            this.UtilsPublic.document.querySelector('#pageCSS')?.remove();
            this.UtilsPublic.document.querySelector('#customCSS')?.remove();
            this.UtilsPublic.document.querySelector('#customJS')?.remove();
            this.UtilsPublic.document.querySelectorAll('.brand-css').forEach(ele => ele.remove());
            this.UtilsPublic.document.querySelectorAll('.brand-js').forEach(ele => ele.remove());

        }
    }

    setGoogleAnalytics(id: string): void {
        this.UtilsPublic.loadJS('https://www.googletagmanager.com/gtag/js?id=' + id, true);
        const script = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
        
            gtag('config', '${id}');
        `;
        this.UtilsPublic.loadJSInline(script);
    }

    setGoogleTagManager(id: string): void {
        const script = `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${id}');
        `;
        this.UtilsPublic.loadJSInline(script);
    }

    setCustomFonts(fonts: { url: string; family: string; }[]): void {
        for (const font of fonts) {
            this.UtilsPublic.loadCSS(font.url);
        }
    }
}