import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Entrant } from 'src/interfaces/entrants/entrant';
import { Question } from 'src/interfaces/questions/question';
import { PageBlockFormQuestionComponent } from '../page-block-form-question.component';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
    selector: 'app-page-block-form-question-recaptcha',
    templateUrl: './page-block-form-question-recaptcha.component.html',
    styleUrls: ['./page-block-form-question-recaptcha.component.scss']
})
export class PageBlockFormQuestionRecaptchaComponent implements OnInit {

    @Input() formGroup!: FormGroup;
    @Input() question!: Question;
    @Input() entrant?: Entrant;
    @Input() controller?: PageBlockFormQuestionComponent;

    controlName: string = this.question?.key;
    control: FormControl = this.formBuilder?.control('', []);

    constructor(private formBuilder: FormBuilder) {
    }

    ngOnInit(): void {
        this.controlName = this.question.key;
        this.setValidation();
    }

    resolved(captchaResponse: string) {
        if (captchaResponse) {
            this.control.setValue(captchaResponse);
        } else {
            this.control.reset();
        }
    }

    setValidation(): void {
        this.control.setValidators([Validators.required, Validators.minLength(30)]);
        this.formGroup.addControl(this.controlName, this.control);
    }

    ngOnDestroy(): void {
        this.formGroup.removeControl(this.controlName);
    }

}
