import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatSelectChange } from '@angular/material/select';
import { Observable } from 'rxjs/internal/Observable';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import { from } from 'rxjs/internal/observable/from';
import { map } from 'rxjs/internal/operators/map';

import { startWith } from 'rxjs/internal/operators/startWith';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { tap } from 'rxjs/internal/operators/tap';
import { ValidationService } from 'src/app/services/validation.service';
import { Entrant } from 'src/interfaces/entrants/entrant';
import { PageBlockFormQuestion } from 'src/interfaces/pages/pageBlockFormQuestion';
import { Question } from 'src/interfaces/questions/question';

@Component({
    selector: 'app-page-block-form-question-address',
    templateUrl: './page-block-form-question-address.component.html',
    styleUrls: ['./page-block-form-question-address.component.scss']
})
export class PageBlockFormQuestionAddressComponent implements OnInit, PageBlockFormQuestion {

    @Input() formGroup!: FormGroup;
    @Input() question!: Question;
    @Input() entrant?: Entrant;


    controlName: string = this.question?.key;
    control: FormControl = this.formBuilder?.control('', []);
    controlId: FormControl = this.formBuilder?.control('', []);

    options!: Observable<{ description: string, place_id: string }[]>;
    loading: boolean = false;

    selectedAddressId: string = '';
    selectedAddress: string = '';
    addresses: { description: string, place_id: string }[] = [];

    constructor(private formBuilder: FormBuilder, public Validation: ValidationService, private http: HttpClient) {

    }

    ngOnInit(): void {
        this.controlName = this.question.key;

        this.setValidation();
        this.Validation.setCustomValidation(this.question, this.question.options.validations, this.control);

        this.options = this.control.valueChanges.pipe(
            switchMap(async input => {
                if (!input)
                    return [];

                const existsInSet = this.addresses.filter(address => address.description === input);
                if (existsInSet && existsInSet.length)
                    return existsInSet;

                this.loading = true;

                const response = await firstValueFrom(this.http.get<any>(`/api/google?tool=autocomplete&input=${input}&types=geocode&language=en`));
                this.loading = false;

                if (response && response.data && response.data.predictions) {
                    return response.data.predictions;
                }

                return [];
            }),
            tap((addresses: { description: string, place_id: string }[]) => this.addresses = addresses)
        );
    }

    select(event: MatOptionSelectionChange<string>): void {
        this.selectedAddress = event.source.value;
        this.selectedAddressId = '';
        setTimeout(() => {
            const address = this.addresses.find(address => address.description = event.source.value);
            if (address)
                this.selectedAddressId = address.place_id;

            this.controlId.setValue(this.selectedAddressId);
        }, 250)
    }

    setValidation(): void {
        this.control.addValidators(this.isSelectedAddress())
        this.formGroup.addControl(this.controlName, this.control);
        this.formGroup.addControl(this.controlName + 'Id', this.controlId);

    }

    isSelectedAddress(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const value = control.value;
            let isValid = false;

            if (value === this.selectedAddress && this.addresses.find(address => address.description === value)) {
                isValid = true;
            }

            return isValid ? null : { 'isSelected': true };
        };
    }

    ngOnDestroy(): void {
        this.formGroup.removeControl(this.controlName);
    }
}
