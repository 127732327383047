import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { UtilsPublicService } from 'src/app/services/utils-public.service';
import { PrizeGame } from 'src/interfaces/prizes/prizeGame';
import { PrizeGamesTypes } from 'src/interfaces/prizes/prizeGamesTypes';

@Component({
    selector: 'app-prize-game-slot-machine',
    templateUrl: './prize-game-slot-machine.component.html',
    styleUrls: ['./prize-game-slot-machine.component.scss']
})
export class PrizeGameSlotMachineComponent implements OnInit {
    @Input() type!: keyof PrizeGamesTypes | string;
    @Input() hasWon!: boolean;
    @Input() game!: PrizeGame;
    @Input() revealedTrigger!: Function;
    @Input() isAdmin!: boolean;
    @Input() demo!: boolean;

    revealInterval: any;
    sentTrigger: boolean = false;

    //
    amountOfSlotsMultiplier: number = 2;
    items: string[] = [];


    constructor(@Inject(DOCUMENT) private document: Document, private UtilsPublic: UtilsPublicService) { }

    ngOnInit(): void {

        setTimeout(() => {
            this.setup();
        }, 250);
        this.setup();
    }

    setup(): void {
        const items: string[] = []
        Object.keys(this.game.options).filter(key => key.length > 20).map((key) => items.push(this.game.options[key].image));

        this.items = items;
        this.init();
    }

    async spin(): Promise<void> {

        this.init(false, (this.items.length * this.amountOfSlotsMultiplier), 3);

        const doors: any = this.document.querySelectorAll('.door');

        for (const door of doors) {
            const boxes = door.querySelector('.boxes');
            const duration = parseInt(boxes.style.transitionDuration);
            boxes.style.transform = 'translateY(0)';
            await new Promise((resolve) => setTimeout(resolve, duration * 150));
        }
        setTimeout(() => {

            if (!this.sentTrigger && this.revealedTrigger) {
                this.sentTrigger = true;
                this.revealedTrigger();
            }

            if (this.document.querySelector('.pyro') && !this.demo)
                (this.document.querySelector('.pyro') as HTMLElement).style.display = (this.hasWon ? 'block' : 'none');

        }, (this.items.length * this.amountOfSlotsMultiplier) * (150 * doors.length));
    }

    init(firstInit: boolean = true, groups: number = 1, duration: number = 1): void {


        const doors: any = this.document.querySelectorAll('.door');
        const history = [];
        for (const door of doors) {
            if (firstInit) {
                door.dataset.spinned = '0';
            } else if (door.dataset.spinned === '1') {
                return;
            }

            const boxes = door.querySelector('.boxes');
            const boxesClone = boxes.cloneNode(false);
            let pool = [this.game.options.background];

            if (!firstInit) {
                // const arr = [];
                // for (let n = 0; n < (groups > 0 ? groups : 1); n++) {
                //     arr.push(...this.items);
                // }

                if (this.hasWon === false) { // if they didn't win the draw
                    // do a random outcome
                    // pool = arr;
                    pool = pool.concat(this.items);
                    pool = (this.duplicateArray(this.UtilsPublic.shuffle(pool), this.amountOfSlotsMultiplier));
                    // lets store what we selected randomly, we need to make sure we don't accidentally create a winning scenario
                    const previousImage: any = history[history.length - 1];

                    let winningImage = pool[pool.length - 1];
                    while (winningImage === previousImage) {
                        // same image as last time, lets shuffle and try again!
                        pool = this.UtilsPublic.shuffle(pool);
                        winningImage = pool[pool.length - 1];
                    }

                    history.push(winningImage);
                } else {
                    pool = this.findPrize();
                }

                pool = [this.game.options.background].concat(pool); // push overlay to the front

                boxesClone.addEventListener(
                    'transitionstart',
                    function (this: any) {
                        door.dataset.spinned = '1';
                        this.querySelectorAll('.box').forEach((box: any) => {
                            box.style.filter = 'blur(1px)';
                        });
                    },
                    { once: true }
                );

                boxesClone.addEventListener(
                    'transitionend',
                    function (this: any) {
                        this.querySelectorAll('.box').forEach((box: any, index: any) => {
                            box.style.filter = 'blur(0)';
                            if (index > 0) this.removeChild(box);
                        });
                    },
                    { once: true }
                );
            }

            for (let i = pool.length - 1; i >= 0; i--) {
                const box = this.document.createElement('img');
                box.classList.add('box');
                box.style.width = door.clientWidth + 'px';
                box.style.height = door.clientHeight + 'px';
                box.src = pool[i];
                boxesClone.appendChild(box);
            }
            boxesClone.style.transitionDuration = `${duration > 0 ? duration : 1}s`;
            boxesClone.style.transform = `translateY(-${door.clientHeight * (pool.length - 1)}px)`;
            door.replaceChild(boxesClone, boxes);
        }
    }

    findPrize(): string[] {
        let arrayOfItems: string[] = [];
        const winningImage = this.game.options.win.image;

        arrayOfItems = arrayOfItems.concat(this.items);

        // lets sanity check that our winning tier item has an image in this list
        if (arrayOfItems.indexOf(winningImage) === -1) {
            return [];
        }

        // lets multiply that by the global modifier to gets lots of spinning!
        arrayOfItems = this.duplicateArray(arrayOfItems, this.amountOfSlotsMultiplier);

        // now we have a giant array of items, lets walk backwards to make sure our winning prize is last
        let i = (arrayOfItems.length - 1);
        while (i > 0) {
            if (arrayOfItems[i] === winningImage) {
                break;
            } else {
                arrayOfItems.splice(i, 1);
                i = arrayOfItems.length - 1;
            }
        }

        return arrayOfItems;

    }

    duplicateArray(arrayOfItems: string[], multiplier: number): string[] {
        for (let i = 0; i < multiplier; i++) {
            arrayOfItems = arrayOfItems.concat(arrayOfItems);
        }
        return arrayOfItems;
    }
}
